import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { Container } from "react-bootstrap"
import ResourcesIntroduction from "../../../components/resourcesIntroduction"
import MarkdownContent from "../../../components/markdownContent"
import Speakers from "../../../components/speakers"
import Cta from "../../../components/cta"
import Favicon from "../../../images/favicon.png"

const Webinar = ({ data, ...props }) => {
  const webinar = data.strapiWebinars

  return (
    <Layout>
      <Container className="page resources single webinar">
        <Seo
          title={
            webinar.seo !== null
              ? webinar.seo.meta_title !== null
                ? webinar.seo.meta_title
                : ""
              : ""
          }
          image={{
            src: webinar.seo.meta_image?.image?.localFile?.publicURL
              ? webinar.seo.meta_image?.image?.localFile?.publicURL
              : null,
          }}
          description={
            webinar.seo !== null
              ? webinar.seo.meta_description !== null
                ? webinar.seo.meta_description
                : ""
              : ""
          }
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <ResourcesIntroduction
          title={webinar.title}
          subtitle="Webinars"
          classes="single-resource_intro"
          padding={{ bottom: 80, top: 80, left: 15, right: 15 }}
          resource={webinar}
          name="Webinars"
        />
        <MarkdownContent content={webinar.content} />
        <Speakers speakers={webinar.speakers} />
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query WebinarPreviewQuery($slug: String!) {
    strapiWebinars(slug: { eq: $slug }) {
      strapiId
      id
      content
      title
      created_at
      updated_at
      published_at
      slug
      intro_text
      webinar_date(formatString: "DD MMMM YYYY", locale: "nl")
      webinar_url
      featured_image {
        alternativeText
        localFile {
          absolutePath
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: WEBP
              width: 1110
            )
          }
        }
        updated_at
      }
      labels {
        id
        slug
        name
        color
      }
      speakers {
        about
        confirmed
        blocked
        email
        full_name
        id
        position
        role
        username
        profile_picture {
          alternativeText
          localFile {
            absolutePath
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        id
        indexable
        meta_description
        meta_keywords
        meta_title
        meta_image {
          meta_image_alt
          image {
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Webinar
